import React from "react"

import { Link } from "gatsby"

//Helpers
import {
  getLocation,
  getName,
  getFakeAddress,
  getStatus,
  is_Sold,
} from "../helpers/helper.developments"
import {
  getCover,
  getQuality,
  getPriceForMarker,
} from "../helpers/helper.rendering"
import { getMinAndMaxPrice } from "../helpers/helper.unities"
import { graphql, useStaticQuery } from "gatsby"
import {
  getEnvironment,
  getPrices,
  getSurface,
  makeLink,
} from "../helpers/helper.properties"
import { connect } from "react-redux"
import { changeSelectedAction } from "../redux/propertiesDucks"
import { useLocation } from "@reach/router"

const developmentCard = props => {
  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        name
      }
    }
  `)
  const { development } = props
  const { dispatch } = props
  const inMap = props?.inMap
  const unities = props?.unities
  const history = useLocation()

  const makeUrlUnities = unities => {
    let url = ""
    unities.forEach((unity, index) => {
      url += `${unity.id}`
      if (index !== unities.length - 1) {
        url += `-`
      }
    })
    return url
  }

  const conditionalStyle = () => {
    if (history.pathname.length === 1 && history.pathname === "/") {
      return " home-card"
    } else if (
      history.pathname.length === 17 &&
      history.pathname === "/emprendimientos/"
    ) {
      return " dev-card"
    }
  }

  return inMap ? (
    <div class={"card prop-card " + (inMap ? "map-card" : "")}>
      <div class={"card-image grid_map_image"}>
        <a target={"_blank"}>
          <img
            src={getQuality(getCover(development.photos))}
            className="notScale"
            alt={realEstate?.name}
          />
        </a>
        <div class="badges">
          <div className={"badge"}>{"EMPRENDIMIENTO"}</div>
        </div>
        <div
          onClick={() => dispatch(changeSelectedAction(0))}
          data-bs-dismiss="modal"
          className="close-card"
        >
          <i></i>
          <i></i>
        </div>
      </div>
      <div class="card-body">
        <div class="content-extra row d-flex justify-content-between align-items-start">
          <div class="col-12 col-lg-6 mb-3 extra-data">
            <span>{getFakeAddress(development)}</span>
          </div>
          <div class="col-12 col-lg-6 mb-2 text-lg-end location">
            <span>{getLocation(development)}</span>
          </div>
        </div>
        <div class="content-extra">
          <h3 class="card-title d-flex flex-column mb-3">
            <a
              className=""
              target={"_blank"}
              href={
                "/emprendimientos/" +
                development.id +
                (props.unities
                  ? "?unidades=" + makeUrlUnities(props.unities)
                  : "")
              }
            >
              {getName(development)}
            </a>
          </h3>
          <div
            className={"col-12 mb-3 " + (unities.length < 7 ? "d-none" : "")}
          >
            {"Desde USD " +
              Intl.NumberFormat("de-DE").format(
                getMinAndMaxPrice(unities).min
              ) +
              " Hasta USD " +
              Intl.NumberFormat("de-DE").format(getMinAndMaxPrice(unities).max)}
          </div>
          <div className="unity mb-3">
            {unities.length < 7
              ? unities
                  .sort((a, b) => a.room_amount - b.room_amount)
                  .map((unity, index) => (
                    <div className="item-unity mb-1 d-flex align-items-center">
                      {getEnvironment(unity)} ambientes ·{" "}
                      {getSurface(unity) || "000.00 M2"} ·{" "}
                      {getPriceForMarker(getPrices(unity)[0])}{" "}
                      <a
                        className="ms-auto"
                        target={"_blank"}
                        href={makeLink(unity)}
                      >
                        VER{" "}
                      </a>
                    </div>
                  ))
              : ""}
          </div>
          {/* <h3 class={"card-title d-flex flex-column " + (unities.length < 7 ? 'd-none' : '')}> */}
          <h3 class={"card-title d-flex flex-column "}>
            <a
              className="btn"
              target={"_blank"}
              href={
                "/emprendimientos/" +
                development.id +
                (props.unities
                  ? "?unidades=" + makeUrlUnities(props.unities)
                  : "")
              }
            >
              Ver emprendimiento
            </a>
          </h3>
        </div>
      </div>
    </div>
  ) : (
    // <div class={"card dev-card map-card" + (props.inMap ? 'map-card' : '')}>
    <a
      target={"_blank"}
      href={
        "/emprendimientos/" +
        development.id +
        (props.unities ? "?unidades=" + makeUrlUnities(props.unities) : "")
      }
      className={"link-card-wrapper"}
    >
      <div class={"card dev-card map-card"}>
        {is_Sold(development) && (
          <div className="development-end-tag">100% VENDIDO</div>
        )}
        <div class="card-image">
          <img
            class="card-img"
            src={development && getQuality(getCover(development.photos))}
            alt={realEstate?.name}
          />
        </div>
        <div class="card-img-overlay d-flex align-items-end align-items-lg-start flex-lg-column text-white">
          <div class="content">
            <div class="badge">
              Estado <span className={`separator ${conditionalStyle()}`}>
                {(conditionalStyle().trim() === "dev-card") ? "————" : "—"}</span>{" "}
              {development && getStatus(development)}
            </div>
            <h4 class="card-title mb-0 mt-lg-5">
              {development && getName(development)}
            </h4>
            <h5 class="d-inline-block card-location direccion mb-0 mb-lg-3 mt-lg-2">
              {development && (getFakeAddress(development).length > 0 
                ? getFakeAddress(development) + "," : null)}{" "}
              {development && getLocation(development, true)[0]}
              {/* <br /> */}
              {/* {development &&  getLocation(development,true)[1]} */}
            </h5>
            {/* <h5 class="card-location">
                            {development && getLocation(development,true)[0]}
                            <br />
                            {development &&  getLocation(development,true)[1]}
                        </h5> */}
          </div>
          {
            props.inMap ? (
              <a
                target={"_blank"}
                href={
                  "/emprendimientos/" +
                  development.id +
                  (props.unities
                    ? "?unidades=" + makeUrlUnities(props.unities)
                    : "")
                }
                class="btn btn-outline mt-auto"
              >
                <span class="d-none d-lg-inline">DESCUBRILO</span>
                <span class="icon-plus d-lg-none"></span>
              </a>
            ) : (
              <a
                target={"_blank"}
                href={
                  "/emprendimientos/" +
                  development.id +
                  (props.unities
                    ? "?unidades=" + makeUrlUnities(props.unities)
                    : "")
                }
                class={"btn btn-outline mt-auto" + conditionalStyle()}
              >
                <span class="d-none d-lg-inline">DESCUBRILO</span>
                <span class="icon-plus d-lg-none"></span>
              </a>
            )

            // <Link to={"/emprendimientos/" + development.id + (props.unities ? '?unidades=' + makeUrlUnities(props.unities) : '')} class="btn btn-outline mt-auto"><span class="d-none d-lg-inline">DESCUBRILO</span><span class="icon-plus d-lg-none"></span></Link>
          }
        </div>
      </div>
    </a>
  )
}

export default connect(
  state => ({
    settings: state.settings,
  }),
  null
)(developmentCard)
